import { deleteCookie } from 'cookies-next'
import { getCurrentDomain } from 'lib/helpers/getCurrentDomain/getCurrentDomain'

type TOptions = Parameters<typeof deleteCookie>[1]

export const deleteTokenCookie = () => {
  const options: TOptions = {}

  // Look at the implementation for the setTokenCookie for
  // details on the "domain" and "path" props
  if (process.env.REDIRECT_TO_BRAND) {
    options.domain = getCurrentDomain()
    options.path = '/'
  }

  // make sure token without additional params is also deleted
  deleteCookie('token')
  deleteCookie('token', options)
}
